import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';

import { PatientDto, Patient, NewPatientRequest } from '@dto';
import { Relationship } from '@enums';
import { Patient as LegacyPatient } from '@models';

import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  /* eslint-disable @typescript-eslint/member-ordering */
  // All patients the current user has access to.
  private _patients: BehaviorSubject<LegacyPatient[]> = new BehaviorSubject<LegacyPatient[]>([]);
  public patients: Observable<LegacyPatient[]> = this._patients.asObservable();
  private patientRefresh: Subject<boolean> = new Subject<boolean>();

  // The current patient the user is acting as
  private _activePatient: BehaviorSubject<LegacyPatient> = new BehaviorSubject<LegacyPatient>(null);
  public activePatient: Observable<LegacyPatient> = this._activePatient.asObservable();

  constructor(private authService: AuthService, private http: HttpClient) {
    combineLatest([this.authService.authState, this.patientRefresh.pipe(startWith(null))])
      .pipe(
        switchMap((authState) => {
          if (authState[0] === null) {
            return of([]);
          }
          return this.getPatients();
        })
      )
      .subscribe((result) => this._patients.next(result));

    this._patients.subscribe((patients) => {
      // If no currentActivePatient, set to default
      const defaultPatient = patients.length ? patients[0] : null;

      if (this.currentActivePatient === null || patients.length === 0) {
        return this._activePatient.next(defaultPatient);
      }

      const updatedPatient = patients.find((patient) => patient.id === this.currentActivePatient.id);

      if (updatedPatient.id !== this.currentActivePatient.id) {
        this._activePatient.next(updatedPatient || defaultPatient);
      }
    });
  }

  get currentActivePatient() {
    return this._activePatient.getValue();
  }

  get patientsSnapshot() {
    return this._patients.getValue();
  }

  get primaryPatient() {
    return this.patients.pipe(
      // tslint:disable-next-line:triple-equals
      map((patients) => patients.find((patient) => patient.relationToAccountHolder === Relationship.Self))
    );
  }

  public refreshPatientList() {
    this.patientRefresh.next(true);
  }

  public addPatientToAccount(newPatientData: NewPatientRequest): Observable<Patient> {
    return this.http
      .post<Patient>(`${environment.niceServiceUrl}/v1/users`, {
        type: 'CreateUserRequest::Patient::V2',
        ...newPatientData,
      })
      .pipe(tap(() => this.refreshPatients()));
  }

  public updatePatient(patientData: Patient): Observable<Patient> {
    return this.http
      .put<Patient>(`${environment.niceServiceUrl}/v1/users/${patientData.id}`, {
        type: 'UpdateUserRequest::Patient::UpdatePatient::V2',
        ...patientData,
      })
      .pipe(tap(() => this.refreshPatients()));
  }

  public async setActivePatient(patient: LegacyPatient) {
    await this.refreshPatients();
    this._activePatient.next(patient);
  }

  public getPatient(patientId: number): LegacyPatient | undefined {
    return this._patients.getValue().find((patient: LegacyPatient) => patient.id === patientId);
  }

  private getPatients() {
    return this.http
      .get<PatientDto[]>(`${environment.apiUrl}/patients/me`)
      .pipe(map((patients) => patients.map((patient) => new LegacyPatient(patient))));
  }

  private async refreshPatients() {
    const allPatients = await this.getPatients().toPromise();
    return this._patients.next(allPatients);
  }
}
