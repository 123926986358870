export enum ChannelType {
  provider,
  coordinator,
  internal,
}

export enum ChatTabs {
  Dashboard = 'dashboard',
  Unread = 'unread',
  Addressed = 'addressed',
}

export enum ChatReadFilter {
  All = 'Show All',
  Unread = 'Show unread only',
}

export enum ChatChannelFilter {
  All = 'Show all chat channels',
  Internal = 'Internal',
  Provider = 'Patient to Provider',
  Coordinator = 'Patient to Care Coordinator',
}
