export const version = {
  current: '1.142.0',
  minimum: '1.91.0',
};

export enum ApplicationType {
  PATIENT = 'patient',
  PROVIDER = 'provider',
}

export interface ClientConfiguration {
  isUsingIdentityService: boolean;
  isProviderReadonly: boolean;
  isLegacyAuth0: boolean; // Do not remove unless all clients have been updated first (1.79.0+).
  isSignUpDisabled: boolean;
  clientID: string;
  clientId?: string; // Used for Cordova builds (doesn't like the capital 'D').
  domain: string;
  responseType: string;
  redirectUri: string;
  scope: string;
  audience: string;
  packageIdentifier?: string;
}
