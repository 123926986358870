import React, { PropsWithChildren } from 'react';
import { Box, Divider, Drawer, Toolbar } from '@mui/material';
import {
  AddCommentOutlined,
  AssignmentOutlined,
  CalendarMonthOutlined,
  GroupAddOutlined,
  MedicationOutlined,
  PaymentOutlined,
} from '@mui/icons-material';
import { Color } from '../../../react/theme/colors';
import ImageView from '../../../react/ImageView/ImageView';
import { Router } from '@angular/router';
import SidebarNavigationMenu from '../SidebarNavigationMenu/SidebarNavigationMenu';
import { environment } from '../../../../environments/environment';

export type SidebarProps = PropsWithChildren<{
  router: Router;
}>;

const drawerWidth = 220;

export function Sidebar({ router }: SidebarProps): React.JSX.Element {
  return (
    <Drawer
      sx={{
        bgcolor: Color.BaseWhite,
        border: '1px solid ' + Color.Slate500,
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant='permanent'
      anchor='left'
    >
      <Toolbar>
        <Box
          sx={{
            width: drawerWidth,
            minHeight: 'inherit',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ImageView imageTarget={'../../../../assets/nice-logo-purple-text.png'} maxHeight='30px' maxWidth='94px' />
        </Box>
      </Toolbar>
      <Divider />
      <SidebarNavigationMenu
        router={router}
        menuItems={[
          {
            title: 'Appointments',
            externalLink: environment.newWebUrl + '/appointments',
            icon: <CalendarMonthOutlined />,
          },
          { title: 'Chat', internalLink: '/tabs/chat', icon: <AddCommentOutlined /> },
          { title: 'Documents', internalLink: '/tabs/documents', icon: <AssignmentOutlined /> },
          { title: 'Patients', internalLink: '/tabs/patients', icon: <GroupAddOutlined /> },
          {
            title: 'Pharmacy Card',
            externalLink: environment.newWebUrl + '/pharmacy-card',
            icon: <MedicationOutlined />,
          },
          { title: 'Billing', internalLink: '/billing', icon: <PaymentOutlined /> },
        ]}
      />
    </Drawer>
  );
}

export default Sidebar;
